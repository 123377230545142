



import { Component, Vue } from 'vue-property-decorator';
import FlipCountdown from 'vue2-flip-countdown'

@Component({
	components: {
		FlipCountdown,
	},
})
export default class Countdown extends Vue { 	}


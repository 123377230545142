import Vue from 'vue'
import App from './App.vue'
import './assets/animation.css';
import 'locomotive-scroll/dist/locomotive-scroll.css';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')






















import { Component, Vue } from 'vue-property-decorator';
import LocomotiveScroll from 'locomotive-scroll';
import Registry from './components/Registry.vue';
import Trees from './components/Trees.vue';
import Animals from './components/Animals.vue';
import Venue from './components/Venue.vue';
import Countdown from './components/Countdown.vue';
import RSVP from './components/RSVP.vue';

@Component({
	components: {
		Registry,
		Venue,
		Trees,
		Animals,
		Countdown,
		RSVP,
	},
})
export default class App extends Vue { 


	public mounted() {
		const scroll = new LocomotiveScroll();
		
	}

}

